export enum PagesPaths {
  MINOR_ILLNESS = '/pharmacy/minor-illness',
  URGENT_SUPPLY = '/pharmacy/urgent-supply',
  REFERRALS = '/pharmacy/referrals',
  REFERRAL_DETAILS = '/pharmacy/referral-details',
  REFERRAL_SUMMARY = '/pharmacy/referral-summary',
  MANUAL_REFERRAL = '/pharmacy/manual-referral',
  CLINICAL_PATHWAYS_WALK_IN_PAGE = '/pharmacy/clinical-pathways-walk-in',
  ADD_REFERRAL_PAGE = '/pharmacy/add-referral',
  REFERRAL_PATIENT = '/pharmacy/referrals/patient',
  PROFILE = '/pharmacy/user-profile',
  HOME = '/pharmacy/home',
  DEFAULT = '/pharmacy/',
  NmsPagesPaths = 'NmsPagesPaths',
  HCF_SUMMARY = '/pharmacy/hcf-episode/summary',
  DISCHARGE_MEDICINES_REFERRALS = '/pharmacy/dms/referrals',
  MULTI_SITE = '/pharmacy/multi-site',
  PRIVACY_POLICY = 'https://www.cegedim-healthcare.co.uk/product-privacy-policy',
}

export enum NmsPagesPaths {
  NEW_MEDICINE_SERVICE = '/pharmacy/new-medicine-service',
  NMS_EPISODE = '/pharmacy/nms-episode',
}

export enum FluVaccinationPagesPaths {
  FLU_VACCINATION_SERVICE = '/pharmacy/flu-vaccination-service',
  FLU_VACCINATION_EPISODE = '/pharmacy/flu-vaccination-episode',
}

export enum HcfPagesPaths {
  HCF_SERVICE = '/pharmacy/hcf-service',
  HCF_EPISODE = '/pharmacy/hcf-episode',
}

export enum PcsPagesPaths {
  PCS_SERVICE = '/pharmacy/pcs-service',
  PCS_EPISODE = '/pharmacy/pcs-episode',
}

export enum DmsPagesPaths {
  DMS_MANUAL_REFERRAL = '/pharmacy/dms/manual-referral',
  DMS_REFERRAL = '/pharmacy/dms/dmsReferral',
  SESSION = '/pharmacy/dms/session',
  SUMMARY = '/pharmacy/dms/summary',
}

export enum VaccinationPaths {
  MAIN = '/pharmacy/vaccinations',
  NEW_PATCH = '/pharmacy/vaccinations/new-patch',
}
