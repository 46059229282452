import React, { Suspense, useEffect, useRef } from 'react';
import { AppContainer, LoadingSpinner } from '@crx-dev/hss-react-components';
import HealthiLogo from '@assets/Pharmacy_Services.svg';
import KeyCloakAuth from '@security/KeyCloakAuth';
import { parseJwt } from '@utils/TokenHelper';
import useApiRequest from '@common/hooks/useApiRequest';
import { PharmacyStore } from '@models/common/PharmacyStore';
import { useNavigate, Outlet, Link } from 'react-router-dom-v6';
import { PagesPaths } from '@enums/PagesPathsEnum';
import { User } from '@models/common/User';
import { getLocumInfo } from '@components/InterceptLocumUser/InterceptLocumUser';
import { GET_PHARMACY_STORE_DETAILS } from '@api/urls/UserUrls';
import { LOCUM, MULTI_SITE } from './roles';
import SideBar from '@components/SideBar/SideBar';
import { useBrowserCookieNoticeState } from '@crx-dev/hss-react-infrastructure';
import { useApplicationState } from '@context/ApplicationState';
import { PatientProvider } from '@context/PatientProvider';

const COOKIE_NOTICE_VERSION = 1;

const AppDetails: React.FC = () => {
  const navigate = useNavigate();
  const { globalState, setGlobalState } = useApplicationState();
  const [pharmacyStoreData, setPharmacyStoreData] = useApiRequest<PharmacyStore>(
    'GET',
    GET_PHARMACY_STORE_DETAILS,
    PharmacyStore,
  );
  const storeId = useRef('');
  const cookieNotice = useBrowserCookieNoticeState({
    version: COOKIE_NOTICE_VERSION,
  });

  const getLoggedInUser = () => {
    const user = new User();
    const token = localStorage.getItem(KeyCloakAuth.TOKEN_STORAGE_KEY);
    if (token) {
      const actualObject = parseJwt(token);
      storeId.current = actualObject.STORE_ID;
      const locumInfo = getLocumInfo();

      if (KeyCloakAuth.hasRole(MULTI_SITE) && !sessionStorage.getItem('storeId')) {
        navigate('/pharmacy/multi-site', { replace: true });
      }

      if (KeyCloakAuth.hasRole(LOCUM) && locumInfo !== null) {
        console.log('Locum user needs to ignore token name info and use:', locumInfo);
        user.firstName = locumInfo.firstName;
        user.lastName = locumInfo.lastName;
        user.gphc = locumInfo.gphcNumber;
      } else {
        user.firstName = actualObject.given_name;
        user.lastName = actualObject.family_name;
        user.gphc = actualObject.GPHC;
      }
    } else {
      user.firstName = 'Pharmacist';
    }
    setGlobalState({ ...globalState, user });
  };

  const getPharmacyStore = () => {
    const getPharmacyStoreUrl = GET_PHARMACY_STORE_DETAILS.replace('{storeId}', storeId?.current);
    setPharmacyStoreData.setUrl(getPharmacyStoreUrl);
    setPharmacyStoreData
      .makePromiseRequest()
      .then((result: PharmacyStore) => {
        setPharmacyStoreData.setData(result);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const routeToPage = (routerPath: PagesPaths, routerState?: any) => {
    navigate(routerPath, { state: routerState });
  };

  const constructUserName = () => {
    const userName = (globalState?.user?.firstName + ' ' + globalState?.user?.lastName).split(/([_\W])/);
    return userName
      .map((name: string) => {
        return name[0]?.toUpperCase() + name?.substring(1)?.toLocaleLowerCase();
      })
      .join('');
  };

  useEffect(() => {
    getLoggedInUser();
    getPharmacyStore();
    /* eslint-disable */
  }, []);
  /* eslint-enable */

  return (
    <>
      {(import.meta.env?.VITE_ENV === 'staging' || import.meta.env?.MODE === 'development') && (
        <style>{`body > iframe{display:none}`}</style>
      )}
      <AppContainer
        collapseAt={1280}
        logo={<img src={HealthiLogo} alt="PS" />}
        onLogoClick={() => routeToPage(PagesPaths.HOME)}
        userActions={[
          {
            label: 'User Settings',
            onClick: () => {
              routeToPage(PagesPaths.PROFILE);
            },
          },
          {
            label: 'Privacy Policy',
            onClick: () => {
              window.open(PagesPaths.PRIVACY_POLICY, '_blank');
            },
          },
          ...(KeyCloakAuth.hasRole(MULTI_SITE)
            ? [
                {
                  label: 'Switch Pharmacy',
                  onClick: () => {
                    sessionStorage.removeItem('storeId');
                    routeToPage(PagesPaths.MULTI_SITE);
                  },
                },
              ]
            : []),
          {
            label: 'Logout',
            onClick: () => {
              KeyCloakAuth.logout();
            },
          },
        ]}
        userInfo={{
          email: pharmacyStoreData.name,
          name: constructUserName(),
        }}
        navigation={<SideBar />}
        content={
          <Suspense fallback={<LoadingSpinner />}>
            <PatientProvider>
              <Outlet />
            </PatientProvider>
          </Suspense>
        }
      />
      <AppContainer.CookieNotice show={cookieNotice.shouldShow} onAccept={cookieNotice.close}>
        Pharmacy Services uses analytical cookies which help our Product Development team track and understand
        user interactions, e.g. are users logging in from a laptop or mobile device. This information helps
        inform the product development approach, we’re always looking for ways to add valuable insight and
        improve your experience. Information is only processed in a way which does not identify anyone. We do
        not make any attempt to find out the identities of users, please see the ‘about Hotjar’ section of{' '}
        <Link
          to="https://help.hotjar.com/hc/en-us/categories/115001323967-About-Hotjar"
          target="_blank"
          rel="noopener noreferrer"
        >
          Hotjar&apos;s support site.
        </Link>
      </AppContainer.CookieNotice>
    </>
  );
};
export default AppDetails;
