import { Roles, DMS, CPCS, NEW_CPCS, HCF, PCS, NMS, FLUVAC, VAC } from '@src/base/roles';
import {
  PagesPaths,
  DmsPagesPaths,
  HcfPagesPaths,
  PcsPagesPaths,
  NmsPagesPaths,
  FluVaccinationPagesPaths,
  VaccinationPaths,
} from '@enums/PagesPathsEnum';
import { LazyExoticComponent, ComponentType, lazy } from 'react';

interface RouteConfig {
  path: string;
  component: LazyExoticComponent<ComponentType<any>>;
  roles?: Roles[];
}

const dmsRoutes: RouteConfig[] = [
  {
    path: PagesPaths.DISCHARGE_MEDICINES_REFERRALS,
    component: lazy(() => import('@DMS/DmsDashboard/DMSReferrals')),
    roles: [DMS],
  },
  {
    path: DmsPagesPaths.DMS_MANUAL_REFERRAL,
    component: lazy(() => import('@DMS/ManualReferral/DMSManualReferral')),
    roles: [DMS],
  },
  {
    path: DmsPagesPaths.DMS_REFERRAL,
    component: lazy(() => import('@DMS/DmsReferral/DmsReferral')),
    roles: [DMS],
  },
];

const walkInRoutes: RouteConfig[] = [
  {
    path: PagesPaths.CLINICAL_PATHWAYS_WALK_IN_PAGE,
    component: lazy(() => import('@NewCPCS/ClinicalPathwaysWalkIn/ClinicalPathwaysWalkInPage')),
    roles: [CPCS, NEW_CPCS],
  },
  {
    path: PagesPaths.ADD_REFERRAL_PAGE,
    component: lazy(() => import('@NewCPCS/addReferral/AddReferralPage')),
    roles: [CPCS, NEW_CPCS],
  },
  {
    path: PagesPaths.REFERRAL_PATIENT,
    component: lazy(() => import('@components/Shared/NewPatientSearch/PatientManualSearch')),
    roles: [CPCS, NEW_CPCS],
  },
];

const CPCSRoutes: RouteConfig[] = [
  {
    path: PagesPaths.MINOR_ILLNESS,
    component: lazy(() => import('@NewCPCS/MinorIllness/MinorIllness')),
    roles: [CPCS, NEW_CPCS],
  },
  {
    path: PagesPaths.URGENT_SUPPLY,
    component: lazy(() => import('@NewCPCS/UrgentSupply/UrgentMedicineSupply')),
    roles: [CPCS, NEW_CPCS],
  },
  {
    path: PagesPaths.REFERRALS,
    component: lazy(() => import('@NewCPCS/Referrals/Referrals')),
    roles: [CPCS, NEW_CPCS],
  },
  {
    path: PagesPaths.REFERRAL_DETAILS,
    component: lazy(() => import('@ReferralDetails/ReferralDetails')),
    roles: [CPCS],
  },
  {
    path: `${PagesPaths.REFERRAL_SUMMARY}/:referralId?/:referralType?/:episodeId?`,
    component: lazy(() => import('@NewCPCS/ReferralSummaryWrapper/ReferralSummaryWrapper')),
    roles: [CPCS],
  },
  {
    path: PagesPaths.MANUAL_REFERRAL,
    component: lazy(() => import('@NewCPCS/ManualReferral/ManualReferral')),
    roles: [CPCS, NEW_CPCS],
  },
];

const HCFRoutes: RouteConfig[] = [
  {
    path: HcfPagesPaths.HCF_EPISODE,
    component: lazy(() => import('@HCF/HcfEpisode')),
    roles: [HCF],
  },
  {
    path: HcfPagesPaths.HCF_SERVICE,
    component: lazy(() => import('@HCF/HcfDashboard/HcfDashboard')),
    roles: [HCF],
  },
];

const PCSRoutes: RouteConfig[] = [
  {
    path: PcsPagesPaths.PCS_SERVICE,
    component: lazy(() => import('@PCS/PcsDashboard/PcsDashboard')),
    roles: [PCS],
  },
  {
    path: PcsPagesPaths.PCS_EPISODE,
    component: lazy(() => import('@PCS/PcsEpisode/PcsEpisode')),
    roles: [PCS],
  },
];

const NMSRoutes: RouteConfig[] = [
  {
    path: NmsPagesPaths.NEW_MEDICINE_SERVICE,
    component: lazy(() => import('@NMS/NmsReferrals/NmsReferrals')),
    roles: [NMS],
  },
  {
    path: NmsPagesPaths.NMS_EPISODE,
    component: lazy(() => import('@NMS/NmsEpisode/NmsEpisode')),
    roles: [NMS],
  },
];

const FLUVACRoutes: RouteConfig[] = [
  {
    path: FluVaccinationPagesPaths.FLU_VACCINATION_EPISODE,
    component: lazy(() => import('@FluVac/FluVaccinationEpisode')),
    roles: [FLUVAC],
  },
  {
    path: FluVaccinationPagesPaths.FLU_VACCINATION_SERVICE,
    component: lazy(() => import('@FluVac/FluVaccinationDashboard/FluVaccinationDashboard')),
    roles: [FLUVAC],
  },
];

const VaccinationRoutes: RouteConfig[] = [
  {
    path: VaccinationPaths.MAIN,
    component: lazy(() => import('@Vaccination/main/Vaccinations')),
    roles: [VAC],
  },
  {
    path: VaccinationPaths.NEW_PATCH,
    component: lazy(() => import('@Vaccination/batch/NewPatch')),
    roles: [VAC],
  },
];

export {
  dmsRoutes,
  walkInRoutes,
  CPCSRoutes,
  HCFRoutes,
  PCSRoutes,
  NMSRoutes,
  FLUVACRoutes,
  VaccinationRoutes,
};
