import React, { lazy } from 'react';
import { PagesPaths } from '@enums/PagesPathsEnum';
import {
  dmsRoutes,
  walkInRoutes,
  CPCSRoutes,
  HCFRoutes,
  PCSRoutes,
  NMSRoutes,
  FLUVACRoutes,
  VaccinationRoutes,
} from '@common/routes/routeDefinitions';
import { createBrowserRouter, Navigate } from 'react-router-dom-v6';
import AppDetails from '@src/base/AppDetails';
import LazyRoute from '@common/routes/LazyRoute';
import MultiSiteDetailsForm from '@components/MultiSite/MultiSiteDetailsForm';

const UserSettings = lazy(() => import('@UserSettings/UserSettings'));
const HomePage = lazy(() => import('@components/HomePage/HomePage'));

const createRoutes = () => {
  const featureRoutes = [
    ...dmsRoutes,
    ...walkInRoutes,
    ...CPCSRoutes,
    ...HCFRoutes,
    ...PCSRoutes,
    ...NMSRoutes,
    ...FLUVACRoutes,
    ...VaccinationRoutes,
  ].map(({ path, component, roles }) => ({
    path,
    element: <LazyRoute component={component} roles={roles} />,
  }));
  const commonRoutes = [
    {
      path: PagesPaths.HOME,
      element: <LazyRoute component={HomePage} />,
    },
    {
      path: PagesPaths.PROFILE,
      element: <LazyRoute component={UserSettings} />,
      index: true,
    },
  ];

  return createBrowserRouter([
    {
      path: '/',
      element: <AppDetails />,
      children: [...featureRoutes, ...commonRoutes],
    },
    {
      path: PagesPaths.MULTI_SITE,
      element: <MultiSiteDetailsForm />,
    },
    {
      path: '*',
      element: <Navigate to={PagesPaths.HOME} replace />,
    },
  ]);
};
export default createRoutes;
