import React from 'react';
import {
  ActionsLayout,
  Form,
  getFieldProps,
  Inputs,
  Typography,
  Label,
  Button,
  FlexLayout,
} from '@crx-dev/hss-react-components';
import { useFormik } from 'formik';
import styles from './LocumDetailsForm.module.css';
import { LocomDetailsFormFields } from '@models/common/LocumDetails';
import { LocumDetailsSchema } from './LocumDetails.constants';
import KeyCloakAuth from '@security/KeyCloakAuth';

interface LocumDetailsFormProps {
  onSubmit: (values: LocomDetailsFormFields) => void;
}

const LocumDetailsForm = ({ onSubmit }: LocumDetailsFormProps) => {
  const formik = useFormik<LocomDetailsFormFields>({
    initialValues: {
      firstName: '',
      lastName: '',
      gphcNumber: '',
    },
    validationSchema: LocumDetailsSchema,
    onSubmit,
  });

  return (
    <div className={styles.root}>
      <div className={styles.form}>
        <Form onSubmit={formik.handleSubmit}>
          <div className={styles.title}>Pharmacist Details</div>
          <FlexLayout marginBottom={25}>
            <Typography center={true}>
              Please enter the details of the responsible pharmacist currently on duty
            </Typography>
          </FlexLayout>
          <Label label="First Name" />
          <Inputs.Text maxLength={50} {...getFieldProps(formik, 'firstName')} />

          <Label label="Last Name" />
          <Inputs.Text maxLength={50} {...getFieldProps(formik, 'lastName')} />

          <Label label="GPhC Number" />
          <Inputs.Text maxLength={50} {...getFieldProps(formik, 'gphcNumber')} />

          <ActionsLayout layout="inline">
            {/*@ts-expect-error to be solved by storybook*/}
            <Button variant={'tertiary'} id="formCancel_btn" onClick={() => KeyCloakAuth.logout()}>
              Cancel
            </Button>
            {/*@ts-expect-error to be solved by storybook*/}
            <Button
              variant={'primary'}
              id="save"
              type="submit"
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              Continue
            </Button>
          </ActionsLayout>
        </Form>
      </div>
    </div>
  );
};

export default LocumDetailsForm;
